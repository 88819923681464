<template>
    <div>
        <v-card elevation="0">
             <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:white" class="text-truncate">Carrito</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div style="margin-inline: 15px;" class="mb-15 mt-7">
                <v-card :style="(envioGratis)?'background-color: #f3c700':'background-color: #fba598'" elevation="5" v-if="!cargando && (mostrarMontoParaEnvioGratis || envioGratis) && pedir">
                    <v-row style="justify-content: center">
                        <v-col class="text-center">
                            <p v-if="mostrarMontoParaEnvioGratis" style="font-size: 17px;margin: 0;">Sumá a tu carrito <b>{{Math.round(restanteParaEnvioGratis) | toCurrency}}</b> más para que el envío sea <b>GRATIS</b> </p>
                            <p v-if="envioGratis" style="color:black;font-weight: 600;margin: 0;font-size: larger;">Envío Gratis</p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-list three-line v-if="this.carrito.length>0" elevation="0" class="mt-5">
                    <template v-for="(item, index) in carrito" >
                        
                        <v-list-item :key="item.cod_art">
                        
                        <v-list-item-avatar :rounded="true" size="80px" horizontal>
                            <template v-if="item.urlimg">
                                <v-img :src="item.urlimg" contain max-width="160" max-height="160"></v-img>
                            </template>
                            <template v-else>
                                <!-- <Imagen :cod="item.COD_ART"  style="inline-size: fit-content;"/> -->
                                <!-- <v-img contain :src="`http://10.19.0.90:8080/api/articulos/imagen/${item.cod_art}`" max-width="160" max-height="160"/> -->
                                <Imagen v-if="item.imagen" :src="item.imagen" :src2="item.etiqueta"/>
                            </template>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            {{(item.desc_cliente)?item.desc_cliente:item.descripcion}}
                            <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.cod_art}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Empaque: </b><b style="color:orange;font-size:17px">{{(item.escala!=item.esc)?item.escala:''}} {{item.cant_empaq}} {{item.esc}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="mostrarPrecioArt(item)"><span><b>Precio contado: </b><b style="color:orange;font-size:17px">{{precioArt(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.cant_empaq!=1 && mostrarPrecioArt(item)"><span><b>Precio por empaque contado: </b><b style="color:orange;font-size:17px">{{precioPorEmpaque(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <div style="justify-content: end; display: grid;">
                                <v-row class="mt-3" style="margin: auto">
                                    <v-btn color="orange" fab icon small @click="disminuirCant(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                    <v-text-field
                                    v-model="item.cantidad"
                                    label="Cantidad"
                                    type="number"
                                    dense flat solo readonly
                                    hide-details="true"
                                    @click="verDialogCantidad(item)"
                                    color="orange"
                                    style="max-width: 95px;height: 37px"
                                    class="centered-input text-orange"
                                    ></v-text-field>
                                    <v-btn color="orange" fab icon small @click="aumentarCant(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                </v-row>
                                <p v-if="item.cantidad> item.disponible/item.cant_empaq" class="mt-2" style="color:orange;text-align: center;max-width: 168px; margin: 0px;">{{ item.cantidad - item.disponible/item.cant_empaq }} con entrega diferida </p>
                                <p class="mt-2" style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                            </div>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn icon @click="itemBorrar=item,dialogConfirmarBorrar=true">
                                <v-icon color="grey lighten-1">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
            </div>
            <!--FOOTER---------------->
            <v-footer color="#ef6b01" padless fixed outlined class="mt-5" style="height: 57px;">
                <v-row justify="center" no-gutters>
                    <v-col cols="4" class="text-start" v-if="usuario.muestra_lista==1">
                        <template v-if="!precioConIva">
                            <b  style="color:white; line-height: 27px;" class="ml-2">{{Math.round(total) | toCurrency}}</b><br>
                        </template>
                        <template v-else>
                            <b style="color:white; line-height: 27px;" class="ml-2">{{Math.round(totalSinIva) | toCurrency}} </b><p v-if="totalSinIva>0" style="display: contents;color:white;font-size: 15px;">más IVA</p><br>
                        </template>
                        <b style="color:white;" class="ml-2">{{carrito.length}} {{(carrito.length==1)?'Producto':'Productos'}}</b>
                    </v-col>
                    <v-col cols="4" class="text-start" v-if="usuario.muestra_lista==0">
                        <b style="color:white;line-height: 55px; font-size: initial;" class="ml-2">{{carrito.length}} {{(carrito.length==1)?'Producto':'Productos'}}</b>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irVentas" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-end" v-if="pedir">
                        <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="siguiente()" :disabled="carrito.length==0">Siguiente<v-icon color="white">mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-end" v-if="!pedir">
                        <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="cotizar()" :disabled="carrito.length==0">Cotizar<v-icon color="white">mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
        <template v-if="this.carrito.length==0" >
            <p style="text-align: center;"><v-icon>mdi-information-outline</v-icon> No hay productos en el carrito</p>
        </template>
        <!-- DIALOG CONFIRMAR BORRAR PRODUCTO -->
        <v-dialog  v-model="dialogConfirmarBorrar" max-width="500" transition="dialog-bottom-transition" @keydown.enter="borrar(itemBorrar)" @keydown.esc="dialogConfirmarBorrar = false">
            <v-card max-width="500">
                <v-card-title>¿Eliminar producto del carrito?</v-card-title>
                <v-card-text>
                    <b>{{this.itemBorrar.descripcion}}</b>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn class="mb-5" outlined dark color="orange" elevation="5" @click="borrar(itemBorrar)">Aceptar</v-btn>
                    <v-btn class="mb-5" outlined dark color="grey" elevation="5" @click="dialogConfirmarBorrar=false">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="itemCantSelected">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="itemCantSelected">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==itemCantSelected.cod_art)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="itemCantSelected" color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="cargando" class="text-center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular><br><br>
            <span class="mt-2">{{mensajeCargando}}</span>
        </v-overlay>
        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar" :color="snackbarColor" text outlined class="mb-15">
            {{ snackbarMsge }}
            <template v-slot:action="{ attrs }">
                <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import moment from 'moment'
import {mapState,mapActions} from 'vuex';
export default {
    name:'Carrito',
    components:{Imagen},
    data(){
        return{
            itemBorrar:'',
            dialogConfirmarBorrar:false,
            cargando:false,
            snackbar:false,
            snackbarColor:'error',
            snackbarMsge:'',
            snackbarIcon:'mdi-check-circle',
            mensajeCargando:'Generando cotización..',
            dialogCantidad:false,
            itemCantSelected:null,
            cliente:null,
            envioCliente:null
        }
    },
    methods:{
         ...mapActions(['agregarAlCarrito','editarCantCarrito','borrarArtCarrito','borrarTodoElCarrito']),
        irVentas(){
            this.$router.push({name: 'Home'});  
        },
        atras(){
            this.$router.go(-1)
        },
        siguiente(){
            this.$router.push({name: 'Envio'});  
        },
        async borrar(item){
            try {
                this.dialogConfirmarBorrar=false
                let index = this.carrito.findIndex(i => i.cod_art==item.cod_art);
                await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                this.borrarArtCarrito(this.carrito[index].id)
                this.snackbar=true
                this.snackbarColor='success'
                this.snackbarIcon='mdi-check-circle'
                this.snackbarMsge='Artículo eliminado'
                await ApiServer.postUsoLog({
                    cod_cli: this.usuario.cod_cli,
                    usuario: this.usuario.nombreUsuario,
                    accion: 'CARRITO_BORRADO',
                    dato: item.cod_art
                })
                
            } catch (error) {
                console.log(error);
            }
        },
        async disminuirCant(item){
            this.cargando = true
            try {
                if(item.cantidad>1){
                    let index = this.carrito.findIndex(i => i==item)
                    let cant = item.cantidad-1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:item.id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }else{
                    this.itemBorrar=item
                    this.dialogConfirmarBorrar=true
                }
                this.cargando = false
            } catch (error) {
                 console.log(error);
                this.cargando = false
            }
        },
        async aumentarCant(item){
            this.cargando = true
            try {
                let index = this.carrito.findIndex(i => i==item)
                if(item.cantidad*item.cant_empaq<this.cantMAx(item)){
                    let cant = item.cantidad+1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:item.id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargando = false
                }else{
                    this.cargando = false
                    this.snackbar = true
                    this.snackbarColor ='error'
                    this.snackbarIcon = 'mdi-close-circle'
                    this.snackbarMsge='Sin stock'
                }
                
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        verDialogCantidad(item){
            this.itemCantSelected = item
            this.dialogCantidad = true
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargando = true
            try {
                let cant = Number(this.itemCantSelected.cantidad)
                if(cant>0 && (cant <= this.itemCantSelected.disponible)){
                    let index = this.carrito.findIndex(i => i==this.itemCantSelected)
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.itemCantSelected.id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }else if (cant>0 && !this.presupuestoVencido(this.itemCantSelected.fec_cancel)){
                    let index = this.carrito.findIndex(i => i==this.itemCantSelected)
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.itemCantSelected.id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargando = false
                this.itemCantSelected = null
                
            } catch (error) {
                this.cargando = false
                this.dialogCantidad = false
                this.itemCantSelected = null
                console.log(error);
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i==this.itemCantSelected)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>=this.itemCantSelected.disponible){
                this.carrito[index].cantidad=this.itemCantSelected.disponible/this.itemCantSelected.cant_empaq   
            }
            if(cant<=0){
                this.carrito[index].cantidad = 1
            }
            this.dialogCantidad = false
            this.itemCantSelected = null
        },
        descEmpaque(item){
            let cant = this.carrito[this.carrito.findIndex(i => i.cod_art==item.cod_art)].cantidad
            if(cant==1){
                if(item.escala != item.esc){
                    return `${cant} ${item.escala} / ${cant*item.cant_empaq} ${item.esc}`
                }else{
                    return `${cant} ${item.escala}`
                }
            }else{
                if(item.escala != item.esc){
                    return `${cant} ${item.esc_plu} / ${cant*item.cant_empaq} ${item.esc}`
                }else{
                    return `${cant} ${item.esc_plu}`
                }
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.pr_final/item.pr_vta
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        presupuestoVencido(fec){
            if(fec){
                let hoy=moment().format('DD-MM-YYYY')
                let fecVenc = moment(fec).format('DD-MM-YYYY')
                let dif = moment(hoy,"DD-MM-YYYY").diff(moment(fecVenc,"DD-MM-YYYY"),'days')
                if(dif<=0){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }       
        },
        async cotizar(){
            try {
                this.cargando = true
                this.mensajeCargando = 'Generando cotización...'
                //TALONARIO
                let talonario = await ApiServer.getTalonarios(259);
                await ApiServer.putTalonarios(259)//TALONARIOS.NRO_CPR + 1
                let fecha = moment().format('DD-MM-YYYY')
                fecha = fecha.replace('-','.')
                fecha = fecha.replace('-','.')
                try {
                    let cpr_id = await ApiServer.getCprIdProcedure()
                    await ApiServer.postCpr({
                        id_pp:cpr_id.CPR_ID,
                        cpretc:11,
                        cprclasif:0,
                        cpr_tipo:"PR",
                        pv:talonario[0].NRO,
                        nro:talonario[0].NRO_CPR,
                        cpr_letra:"X",
                        tal_id:259,
                        fecha: fecha,
                        cli_id:this.usuario.cli_id,
                        rz:this.cliente.RZ,
                        cuit:this.cliente.CUIT,
                        iva_id:this.cliente.IVA_ID.trim(),
                        actividad:this.cliente.IB_ACTIV_ID,
                        dom:this.cliente.DOM,
                        loc_id:this.cliente.LOC_ID,
                        loc:this.cliente.LOCALIDAD,
                        cp:this.cliente.CP,
                        prov_id:this.cliente.PROVINCIA_ID,
                        tipo_pago_id:"CO",
                        lista_id:0,
                        lista:'LISTA BASE',
                        nota_cpr:"",
                        cond_id:'1',
                        computa_iva:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N',
                        discrimina_imp:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N'
                    })
                    //INSERTO CPRDET
                    try {
                        let MTOTAL = 0
                        let MTOTALN = 0
                        let IVA21 = 0
                        let IVA105 = 0
                        for (const element of this.carrito){
                            let cprdet_id =await ApiServer.postCprDet({ //ME RETORNA EL CPRDET_ID
                                id_pp:cpr_id.CPR_ID,
                                art_id:element.art_id,
                                cod_art:element.cod_art,
                                descripcion:element.descripcion,
                                desc_cliente:element.desc_cliente,
                                mod:element.mod,
                                med:element.med,
                                descif:element.desc_if,
                                cant:(element.cant_empaq==0)?(element.cantidad*1):(element.cantidad*element.cant_empaq),
                                escala_id:element.escala_id,
                                escala:element.escala,
                                lista_id:element.lpr_id,
                                pr_cto_cpra:element.pr_cto_cpra,
                                pr_cto:element.pr_cto,
                                prfinal:element.pr_final,
                                pr_vta:element.pr_vta,
                                dep_id_org:33,
                                dep_id_dest:33,
                                porc_dr:0,
                                monto_dr:0
                            }) 
                            //INSERTO CPRDETIMP
                            let PRNETO = element.pr_vta
                            let PRFINAL = element.pr_final
                            let IMPU=0
                            let IMP_ID=0
                            if(element.cant_empaq>1){
                                PRNETO = element.pr_vta*element.cant_empaq
                                PRFINAL = element.pr_final*element.cant_empaq
                            }
                            if(((PRFINAL/PRNETO)-1.21)<=0.01){
                                IVA21=IVA21+(PRNETO*.21*(element.cantidad));
                                IMPU=21;
                                IMP_ID=1;
                            }
                            if(((PRFINAL/PRNETO)-1.105)<=0.01){
                                IVA105=IVA105+(PRNETO*.105*(element.cantidad));
                                IMPU=10.5;
                                IMP_ID=2;
                            }
                            await ApiServer.postCprdetImp({
                                cprdet_id:cprdet_id.CPRDET_ID,
                                imp_id:IMP_ID,
                                impu:IMPU,
                                neto_mas_iva:PRNETO*element.cantidad,
                                monto_iva:PRNETO*(IMPU/100)*(element.cantidad)
                            })
                            //SUMO LOS TOTALES
                            MTOTAL=MTOTAL+(PRFINAL)*(element.cantidad);
                            MTOTALN=MTOTALN+(PRNETO)*(element.cantidad);

                        }
                        //INSERTO EL PIE DEL COMPROBANTE
                        try {
                            await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'-1',
                                porc:'0',
                                porcentaje:'0',
                                total_neto:MTOTALN,
                                total:MTOTAL
                            });
                            await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'0',
                                porc:'0',
                                porcentaje:'0',
                                total_neto:MTOTALN,
                                total:MTOTAL
                            });
                            if(IVA21>=0.01){
                                await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'1',
                                impuesto:'IVA 21%',
                                clasif_id:'1',
                                porc:'21',
                                porcentaje:'0',
                                total_neto:IVA21,
                                total:IVA21/1.21,
                                });
                            }
                            if(IVA105>=0.01){
                                await ApiServer.postCprpie({
                                cpr_id:cpr_id.CPR_ID,
                                impuesto_id:'2',
                                impuesto:'IVA 10.5%',
                                clasif_id:'1',
                                porc:'10.5',
                                porcentaje:'0',
                                total_neto:IVA105,
                                total:IVA105/1.105,
                                }); 
                            }
                            try {
                                await ApiServer.putCprMonto(cpr_id.CPR_ID,{monto:MTOTAL})
                                try {
                                    //CAMBIAR EL ESTADO A CPR_EST_ID=1
                                    await ApiServer.putCprEstado(cpr_id.CPR_ID,{estado:'1'})
                                } catch (error) {
                                    this.cargando=false
                                    console.log(error)
                                    this.snackbar=true
                                    this.snackbarMsge="Se produjo un error al cambiar estado"
                                    this.snackbarColor="error"
                                    this.snackbarIcon='mdi-close-circle'
                                }
                            } catch (error) {
                                this.cargando=false
                                console.log(error)
                                this.snackbar=true
                                this.snackbarMsge="Se produjo un error al actualizar monto"
                                this.snackbarColor="error"
                                this.snackbarIcon='mdi-close-circle' 
                            }
                        } catch (error) {
                            this.cargando=false
                            console.log(error)
                            this.snackbar=true
                            this.snackbarMsge="Se produjo un error al generar cprpie"
                            this.snackbarColor="error"
                            this.snackbarIcon='mdi-close-circle'
                        }
                    } catch (error) {
                        this.cargando=false
                        console.log(error)
                        this.snackbar=true
                        this.snackbarMsge="Se produjo un error al generar cprdet"
                        this.snackbarColor="error"
                        this.snackbarIcon='mdi-close-circle'
                    }
                    this.cargando=false
                    this.snackbar=true
                    this.snackbarMsge="Pedido procesado"
                    this.snackbarColor="success"
                    this.snackbarIcon='mdi-check-circle'
                    await ApiServer.deleteVaciarCarrito(this.usuario.cod_cli)
                    this.borrarTodoElCarrito()
                    // await ApiServer.deleteCarritoMostradorTodos(sessionStorage.getItem("usu_id"))
                    this.$router.push({name:'PedidoIngresado',params:{nroPedido:talonario[0].NRO_CPR}})
                    // localStorage.setItem('carrito', JSON.stringify([]));
                } catch (error) {
                    this.cargando=false
                    console.log(error)
                    this.snackbar=true
                    this.snackbarMsge="Se produjo un error al generar cpr"
                    this.snackbarColor="error"
                    this.snackbarIcon='mdi-close-circle'
                }
            } catch (error) {
                this.cargando=false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Se produjo un error'
            }
        },
        precioArt(item){
            let precio = null
            if(this.precioConIva){
                precio = item.pr_vta + item.pr_vta*(item.descuento/100)
            }else{
                precio = item.pr_final + item.pr_final*(item.descuento/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(item){
            let precio = null
            if(this.precioConIva){
                let pr_vta= item.pr_vta + item.pr_vta*(item.descuento/100)
                precio = Math.round(pr_vta)*item.cant_empaq
            }else{
                let pr_final = item.pr_final + item.pr_final*(item.descuento/100)
                precio = Math.round(pr_final)*item.cant_empaq
            }
            return precio
        },
        cantMAx(item){
            if(!this.presupuestoVencido(item.fec_cancel)){
                if(item.cant>=item.disponible){
                    return item.cant
                }else{
                    return item.disponible
                }
            }else{
                return item.disponible
            }
        },
        mostrarPrecioArt(item){
            if(this.usuario.muestra_lista==1){
                return true
            }else if(item.cpr){
                return true
            }else return false
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        total(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=(item.pr_final + item.pr_final*(item.descuento/100)) * empaque * item.cantidad
                });
            }
            return total
        },
        totalSinIva(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=(item.pr_vta + item.pr_vta*(item.descuento/100)) * empaque * item.cantidad
                });
            }
            return total
        },
        productos(){
            let cant=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    cant+=parseInt(item.cantidad)
                });
                return cant
            }else if(isNaN(cant)){
                return 0
            }else{
                return cant
            }
        },
        validarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>0 && !this.presupuestoVencido(this.itemCantSelected.fec_cancel)){
                return true
            }else if(cant>this.itemCantSelected.disponible || cant<=0){
                return false
            }else{
                return true
            }
            
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
        mostrarMontoParaEnvioGratis(){
            if(this.envioCliente && this.envioCliente.envio_gratis==1){
                let minimo = (this.envioCliente.MontoMinimo/this.envioCliente.Porcentaje)*100
                let total = (this.precioConIva)?this.totalSinIva:this.total
                if(this.carrito.length>0 && total<minimo){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        },
        envioGratis(){
            if(this.envioCliente && this.envioCliente.envio_gratis==1){
                let minimo = (this.envioCliente.MontoMinimo/this.envioCliente.Porcentaje)*100
                if(this.carrito.length>0){
                    if(this.precioConIva){
                        if(this.totalSinIva>=minimo){
                            return true
                        }else{
                            return false
                        }
                    }else{
                         if(this.total>=minimo){
                            return true
                        }else{
                            return false
                        }
                    }
                }else{
                    return false
                }
            }else{
                return false
            }
        },
        restanteParaEnvioGratis(){
            let minimo = ((this.envioCliente.MontoMinimo/this.envioCliente.Porcentaje)*100)
            if(this.precioConIva){
                return minimo - this.totalSinIva 
            }else{
                return minimo - this.total 
            }
        },
        pedir(){
            if(this.usuario.muestra_lista==1){
                return true
            }else{
                if(this.carrito.find(x=>!x.cpr)){
                    return false
                }else{
                    return true
                }

            }

        }
       
    },
    async mounted(){
        if(this.usuario.muestra_lista==0){
            try {
                this.cargando = true
                this.mensajeCargando = ''
                let result = await ApiServer.getDatosCliente(this.usuario.cod_cli)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        this.cliente = result[index]
                    }else{
                        this.cliente = result[0]
                    }
                }
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Error al consultar datos'
            }
        }

        try {
            this.cargando = true
            this.mensajeCargando = ''
            let result = await ApiServer.getFleteCliente(this.usuario.cod_cli)
            if(result.length>0)
            this.envioCliente = result[0]
            this.cargando = false
        } catch (error) {
            this.cargando = false
            console.log(error);
            this.snackbar = true
            this.snackbarColor ='error'
            this.snackbarIcon = 'mdi-close-circle'
            this.snackbarMsge='Error al consultar datos'
        }
        
    },
}
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
    .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
    }
</style>